.AddDocument {
  box-sizing: border-box;
  width: 110px;
  height: 130px;
  background-color: #D5DDDC;
  border: 2px solid #A098AE;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-icon {
    font-weight: 900;
    font-size: 36px;
  }

}
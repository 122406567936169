.PdfCard {
  box-sizing: border-box;
  width: 110px;
  height: 130px;
  background-color: #D5DDDC;
  border: 2px solid #A098AE;
  border-radius: 14px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  &.active {
    @apply bg-iwingreen;
    border: none;

    .PdfCard-delete {
      color: #FFFFFF;
    }
  }


  &-delete {
    position: absolute;
    top: 13px;
    left: 85px;
    font-size: 18px;
    color: #B91212;
  }

  &-caption {
    font-size: 12px;
    height: 19px;
    border-radius: 7px;
    background-color: #FFFFFF;
    width: 83px;
    padding: 0 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 34px;
    margin-bottom: 8px;
  }
}
.Offres{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &-container{
    position: absolute;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 2%;
  }

  &-buttonPrimary, &-buttonOutline {
    @apply px-5 py-4 text-sm rounded-md bottom-1;
    width: 100%; 
  }
  &-buttonPrimary{
    background-color: #FB7D5B;
    color: white;
  }
  &-buttonOutline{
    color: #FB7D5B;
    background-color: white;
  }

  &-head{

  }

  &-body{
    position: relative;
    display: flex;
  }
  &-item{
    background: #FFFFFF;
    border: 1px solid #26A69A;
    border-radius: 20px;
    box-sizing: border-box;
    position: relative;
    height: 600px;
    width: 460px;

    &-head{
      height: 250px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      padding: 10px 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      &.primary{
        color: #FFFFFF;
       background-color: #26A69A;
      }
      &.outline{
        background-color: #26A69A30;
      }
    }
  }
}
.Input {
  font-size: 14px;
  background-color: rgb(50 202 188 / 0.02);
  border-color: rgb(50 202 188 / 0.47);

  :focus {
    border-color: rgb(50 202 188 / 0.47);
  }

  @apply w-full drop-shadow-md outline-none border text-iwinblue rounded-md px-3 py-2;
}
.Spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  &::before {
    content: "";
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    margin: auto;
    border: 0.25rem solid #ccc;
    border-top-color: #009688;
    animation: spinner-rotate 0.75s infinite linear;

  }
}


@keyframes spinner-rotate {
  to {
    transform: rotate(360deg);
  }
}
.Select {
  input {
    border: none;

    :focus {
      border: none !important;
    }
  }
}

.select__control {
  border: none !important;


  :focus {
    border: none !important;
  }
}
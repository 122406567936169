@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

::root {
    /* Color styles */
    --color---iws-orange: #fb7d5b;
    --color---iws-yelFlow: #fcc43e;
    --color---iws-text: #303972;
    --color---iws-white: #ffffff;
    --color---iws-gray: #f5f5f5;
    --color---iws-gray-2: #ffffff;
    --color---iws-gray-3: #a098ae;
    --color---iws-background: #f3f4ff;
    --color---iws-green: #4cbc9a;
    --color---iws-red: #ff4550;
    --linear-amp--transparent---bluegreen-10: #4d44b5;
    /* Text-size styles */
    --text---text--semi--bold-1: 18px;
    --text---text--semi--bold-2: 14px;
    --text---text--medium-1: 18px;
    --text---text--medium-2: 14px;
    --text---text--regular-1: 18px;
    --text---text--regular-2: 14px;
    --heading---heading-1: 36px;
    --heading---heading-2: 32px;
    --heading---heading-3: 24px;
    --heading---heading-4: 18px;
    --heading---heading-5: 14px;
}

body,
html {
    height: 100%;
    min-height: 100%;
}

body {
    background-color: #f3f4ff !important;
    font-family: "Poppins", sans-serif;
}

.side-bar-left {
    background-color: #4d44b5;
    width: 400px;
    min-height: 100%;
}

.side-bar-right {
    background-color: #ffffff;
}

label {
    font-size: 14px;
    font-weight: 600;
    color: #303972;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="date"] {
    width: 100%;
    font-size: 16px;
    color: #303972;
    border: 1px solid rgba(50, 202, 188, 0.47);
    filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
    outline: none;
    border-radius: 0.375rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    color: #303972;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    color: #303972;
}

.page-enter {
    opacity: 0;
    transform: scale(1.1);
}

.fade-appear {
    opacity: 0.01;
}

.fade-appear.fade-appear-active {
    opacity: 1;
    transition: opacity 300ms ease-in-out;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in-out;
}

.fade-exit-done {
    opacity: 0;
}

.fade-enter {
    opacity: 0.01;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in-out;
}

.iwin-shadow {
    box-shadow: 0px 0px 56px -3px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
        0px 10px 10px -3px rgba(0, 0, 0, 0.1);
}

.iwin-shadow-2 {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.scroller {
    max-height: calc(100vh - 90px);
    overflow-y: auto;
    scrollbar-width: thin;
}

.scroller2 {
    height: 65vh;
    max-height: 65vh;
    overflow-y: auto;
    scrollbar-width: thin;
}

.scroller4 {
    height: 65vh;
    max-height: 65vh;
    overflow-y: auto;
    scrollbar-width: thin;
}

.scroller3 {
    max-height: 50vh;
    overflow-y: auto;
    scrollbar-width: thin;
}

.shadow-in {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
}

/* width */
::-webkit-scrollbar {
    width: 0px;
}

.p-datatable-thead>tr>th {
    background-color: white !important;
    font-weight: 500 !important;
}

.p-datatable-tbody>tr>td {
    background-color: #f3f4ff !important;
}

.p-datatable-emptymessage>td {
    text-align: center !important;
}

.p-editor-container {
    width: 100%;
}

.p-editor-toolbar {}

.p-editor-content {
    width: 100%;
}

.p-editor-container .p-editor-toolbar.ql-snow,
.p-editor-container .p-editor-content.ql-snow {
    border: 1px solid #43c7ba96;
}

.p-editor-container .p-editor-content.ql-snow {
    height: 350px !important;
    min-height: 350px !important;
}

.editor-etape>.p-editor-container .p-editor-content.ql-snow {
    height: 100px !important;
    min-height: 100px !important;
}

.p-inputswitch .p-inputswitch-slider:before {
    background: #bbbaba;
    width: 1rem !important;
    height: 1rem !important;
    left: -0.05rem;
    margin-top: -0.5rem !important;
    border-radius: 50%;
    transition-duration: 0.2s;
}

.p-inputswitch {
    width: 2.3rem;
    height: 1rem;
}

.p-inputswitch .p-inputswitch-slider:hover,
.p-inputswitch .p-inputswitch-slider:focus {
    background: #cec9c9c7;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:hover,
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:focus {
    background: #cec9c9c7;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: #cec9c9c7;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    background: #5fec9a;
}

.iwin-gradient {
    background: linear-gradient(180deg, #3284b2 0%, #32b2a3 65.62%);
    color: transparent;
}
.DeleteAccount{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 4;
  &-content{
    position: absolute;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 2%;
  }

  &-cancel, &-apply{
    @apply px-5 py-4 text-sm rounded-md bottom-1;
  }

  &-cancel{
    @apply  border-gray-600 bg-gray-300  font-bold text-gray-600;
  }

  &-apply{
    @apply  border-red-600 bg-red-300  font-bold text-red-600;
  }


  .Actions{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 1%;
  }
}